@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Trenda';
  src: url('./fonts/Latinotype\ Trenda\ Regular.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'TrendaSemiBold';
  src: url('./fonts/Latinotype\ Trenda\ Semibold.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'TrendaBold';
  src: url('./fonts/Latinotype\ Trenda\ Bold.otf') format('opentype');
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown > * {
  all: revert;
}

.markdown a {
  font-weight: bold;
  text-decoration: underline;
}

/*Loading screen*/
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
 
.logo-container {
  position: absolute;
  width: 120px; /* Adjust size as needed */
  height: 120px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -70%;
  z-index: 50;
}
 
.logo {
  width: 60%;
  height: 60%;
  object-fit: contain;
}
 
.loading-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 5px solid #ffffff; /* White border for the loading effect */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
 
/* Animation for the loading circle */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
 