@keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-3px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(3px);
    }
}