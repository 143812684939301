.report-style-class {
    width: 100%; /* ou une largeur spécifique en px */
    height: 100%;
    max-width: 100%; /* une largeur maximale si nécessaire */
    margin: 0 auto; /* pour centrer le rapport */
}

.markdown pre {
    overflow-x: auto;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Pour les navigateurs WebKit (Chrome, Safari, etc.) */
  }
  
.hide-scrollbar {
  -ms-overflow-style: none;  /* Pour IE et Edge */
  scrollbar-width: none;  /* Pour Firefox */
}

/* styles.css */
/* Slider track styles */
.rc-slider-track {
  background-color: blue; /* Change this to the color you want */
}

/* Slider handle styles */
.rc-slider-handle {
  border-color: red; /* Change this to the color you want */
}

.main_title {
  margin-bottom: 5px;
  color: #E10123;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.second_title {
  margin-bottom: 5px;
  color: #000000;
  font-size: 17px;
  text-align: center;
  font-weight: bold;
}
.third_title {
  color: #000000;
  font-size: 15px;
  text-align: center;
}
.introduction {
  color: #000000;
  font-size: 15px;
}
.paragraph_title0 {
  margin-bottom: 5px;
  color: #004489;
  font-size: 20px;
  font-weight: bold;
}
.paragraph_content0 {
  color: #000000;
  font-size: 17px;
  text-align: justify;
}
.paragraph_title1 {
  color: #004489;
  font-weight: bold;
}
.paragraph_content1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 17px;
  color: #000000;
  text-align: justify;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.lds-ellipsis {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.lds-ellipsis-blue {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
}

.lds-ellipsis-blue div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #4f5c90;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis-blue div:nth-child(1) {
  left: 8px;
  animation: blue-ellipsis1 0.6s infinite;
}

.lds-ellipsis-blue div:nth-child(2) {
  left: 8px;
  animation: blue-ellipsis2 0.6s infinite;
}

.lds-ellipsis-blue div:nth-child(3) {
  left: 32px;
  animation: blue-ellipsis2 0.6s infinite;
}

.lds-ellipsis-blue div:nth-child(4) {
  left: 56px;
  animation: blue-ellipsis3 0.6s infinite;
}

@keyframes blue-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blue-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes blue-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.lds-ellipsis-sdh {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
}

.lds-ellipsis-sdh div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: linear-gradient(to bottom right, #3399B1, #116B81);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis-sdh div:nth-child(1) {
  left: 8px;
  animation: sdh-ellipsis1 0.6s infinite;
}

.lds-ellipsis-sdh div:nth-child(2) {
  left: 8px;
  animation: sdh-ellipsis2 0.6s infinite;
}

.lds-ellipsis-sdh div:nth-child(3) {
  left: 32px;
  animation: sdh-ellipsis2 0.6s infinite;
}

.lds-ellipsis-sdh div:nth-child(4) {
  left: 56px;
  animation: sdh-ellipsis3 0.6s infinite;
}

@keyframes sdh-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes sdh-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes sdh-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.empty:before {
  content: attr(data-placeholder);
  color: #9ca3af; /* Gray color */
}