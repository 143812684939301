.blink-border {
  border-style: solid;
  animation: blink-border-animation 2s ease-in-out 2;
}

@keyframes blink-border-animation {
  0%, 100% {
    background-color: #02f647;
  }
  50% {
    background-color: transparent;
  }
}