/* In your global stylesheet, e.g., styles/global.css */
@keyframes scaleIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
  
  
  